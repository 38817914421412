// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-connect-index-js": () => import("./../../../src/pages/connect/index.js" /* webpackChunkName: "component---src-pages-connect-index-js" */),
  "component---src-pages-connect-thanks-js": () => import("./../../../src/pages/connect/thanks.js" /* webpackChunkName: "component---src-pages-connect-thanks-js" */),
  "component---src-templates-bookclub-page-js": () => import("./../../../src/templates/bookclub-page.js" /* webpackChunkName: "component---src-templates-bookclub-page-js" */),
  "component---src-templates-gallery-page-js": () => import("./../../../src/templates/gallery-page.js" /* webpackChunkName: "component---src-templates-gallery-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-thoughts-page-js": () => import("./../../../src/templates/thoughts-page.js" /* webpackChunkName: "component---src-templates-thoughts-page-js" */)
}

